<template>
    <div>
        <div class="d-flex flex-wrap justify-content-center">
            <div v-if="!!error" class="alert alert-danger">
                {{ error }}
            </div>
        </div>

        <div class="d-flex flex-wrap px-4 py-4">
            <div class="col-12">
                <div class="form-group py-2">
                    <vs-input
                        v-model="alt"
                        type="text"
                        name="alt"
                        :disabled="isLoading"
                        label="Alt imagen"
                        state="success"
                        class="input-theme"
                        :class="validAlt ? 'border-custom' : ''"
                        aria-label="Alt"
                        icon-after
                        autocomplete="off"
                        @blur="introValidators.alt = true"
                    >
                        <template #icon> 📷</template>
                        <template #message-danger>
                            <div v-show="!validAlt && introValidators.alt">
                                No ingrese simbolos raros, debe tener entre 8 y
                                64 carácteres.
                            </div>
                        </template>
                    </vs-input>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group py-2">
                    <sizes-images-selector
                        v-model="size"
                        :enabled="!isLoading"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="!uploadFile"
            class="d-flex flex-wrap my-2 d-flex justify-content-center"
            @click="clickButton"
        >
            <label
                class="w-64 d-flex flex-column align-items-center px-4 py-6 btn btn-success rounded text-uppercase"
            >
                <img
                    src="@/assets/images/upload.svg"
                    height="20"
                    width="20"
                    alt="upload img"
                />
                <span class="mt-2 text-base leading-normal">
                    {{ updatedFile ? file.name : "Seleccione archivo" }}
                </span>
                <input
                    id="image-uploader"
                    :disabled="isLoading || !validAlt"
                    type="file"
                    accept="image/*"
                    class="d-none"
                    @input="uploadButton($event)"
                />
            </label>
        </div>
        <div v-else class="d-flex flex-wrap d-flex justify-content-center">
            <img loading="lazy" :src="url" alt="Imagen subida" class="w-64" />
        </div>
        <div class="d-flex flex-wrap d-flex justify-content-center mt-3">
            <vs-button
                gradient
                :active="validInfo"
                :disabled="!validInfo || isLoading"
                @click="createImageComponent"
            >
                Crear imagen
            </vs-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { altRegex } from "@/common/lib/regex";

import SizesImagesSelector from "@/components/utils/SizesImagesSelector.vue";

export default {
    name: "ImageCreator",
    components: { SizesImagesSelector },
    emits: ["created", "loading"],
    data: () => ({
        id: 0,
        enableHelp: false,
        alt: "",
        size: "",
        isLoading: false,
        uploadFile: false,
        file: { name: "", size: 0 },
        updatedFile: false,
        url: "",
        error: "",
        introValidators: { alt: false }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"]),
        validAlt() {
            return altRegex.test(this.alt);
        },
        validInfo() {
            return this.uploadFile && this.validAlt && !!this.size;
        }
    },
    watch: {},
    methods: {
        ...mapActions("images", [
            "getEndPointUploadImage",
            "uploadImage",
            "createImage"
        ]),
        async clickButton() {
            if (!this.validateData()) {
                this.error =
                    "Antes de subir la image verifica haber llenado el campo alt y seleccionado el tipo de imagen.";
            }
        },
        async uploadButton(event) {
            const auxFile = event.target.files || event.dataTransfer.files;
            if (!auxFile.length) return;
            this.file = auxFile[0];
            const fileSize = this.file.size / 1048576;
            if (fileSize > 20) {
                this.error = `Archivo es demasiado pesado: ${fileSize} MB`;
                return;
            }
            this.error = "";
            this.updatedFile = true;
            this.isLoading = true;
            this.$emit("loading", true);
            this.error = "";
            try {
                const { id, url, urlSigned } =
                    await this.getEndPointUploadImage({
                        fileName: this.file.name,
                        fileSize: this.file.size,
                        alt: this.alt,
                        size: this.size
                    });
                this.id = id;
                this.url = url;
                await this.uploadImage({
                    url: urlSigned,
                    file: this.file
                });
                this.uploadFile = true;
            } catch (error) {
                this.error = error.message;
                this.updatedFile = false;
                this.file = { name: "undefined", size: "11111" };
            } finally {
                this.$emit("loading", false);
                this.isLoading = false;
            }
        },
        async createImageComponent() {
            this.isLoading = true;
            this.$emit("loading", true);
            this.error = "";
            try {
                const result = await this.createImage({
                    id: this.id,
                    url: this.url,
                    alt: this.alt,
                    size: this.size
                });
                this.$emit("created", result);
                this.uploadFile = false;
                this.updatedFile = false;
                this.updatedFile = false;
                this.introValidators.alt = false;
                this.file = { name: "undefined", size: 0 };
                this.alt = "";
            } catch (error) {
                this.error = error.message;
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.$emit("loading", false);
                this.isLoading = false;
            }
        },
        validateData() {
            this.introValidators = { alt: false };
            return this.validAlt && !!this.size;
        }
    }
};
</script>
