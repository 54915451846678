export const sizesImages = [
    {
        size: "squareImage",
        title: "Cuadra",
        width: 1200,
        height: 1200,
        strict: true
    },
    {
        size: "customImage",
        title: "Personalizado",
        width: 4200,
        height: 4200,
        strict: false
    }
];

export const sizesImagesAll = [
    {
        size: "squareImage",
        title: "Cuadrada",
        sizes: [
            { size: "squareImage", width: 1200, height: 1200, strict: true }
        ]
    },
    {
        size: "tripleImage",
        title: "Response",
        sizes: [
            { size: "largeImage", width: 1400, height: 600, strict: true },
            { size: "normalImage", width: 778, height: 600, strict: true },
            { size: "smallImage", width: 480, height: 480, strict: true }
        ]
    },
    {
        size: "customImage",
        title: "Personalizado",
        sizes: [
            { size: "customImage", width: 4200, height: 4200, strict: false }
        ]
    },
    {
        size: "smallImage",
        title: "Pequeña",
        sizes: [{ size: "smallImage", width: 64, height: 64, strict: true }]
    },
    {
        size: "normalImage",
        title: "Mediano",
        sizes: [{ size: "normalImage", width: 400, height: 400, strict: true }]
    },
    {
        size: "largeImage",
        title: "Grande",
        sizes: [{ size: "largeImage", width: 1200, height: 1200, strict: true }]
    }
];
